// .hero {
//   background: $color-primary;
//   color: $color-white;
//   position: relative;
//   background: url("../assets/site/drone-fores-2023.jpg") no-repeat center center;
//   background-size: cover;
//   z-index: 0;
//   &::before {
//     content: "";
//     background: rgba($color: $color-primary, $alpha: 0.8);
//     position: absolute;
//     inset: 0;
//   }
//   &__container {
//     position: relative;
//     height: 600px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//     @media screen and (min-width: 992px) {
//       width: 65%;
//       margin-left: auto;
//       margin-right: auto;
//     }
//     @media screen and (max-width: 767px) {
//       height: 400px;
//     }
//   }

//   &__subtitle {
//     font-size: $font-size;
//     text-transform: uppercase;
//     margin-bottom: $font-size;
//     font-weight: bold;
//     letter-spacing: 1px;
//   }

//   &__title {
//     font-weight: $font-weight-bold;
//     font-size: $header-1;
//     margin-bottom: $space-1;
//     line-height: 1.2;
//     @media screen and (max-width: 767px) {
//       font-size: $header-3;
//     }
//   }

//   &__description {
//   }
// }

.fhero {
  background: $color-primary-dark;
  &__wrapper {
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }
  &__picture {
    padding: $space-2;
    @media screen and (max-width: 991px) {
      padding: initial;
    }
    img {
      width: 100%;
      aspect-ratio: 2 / 1.3;
      object-fit: cover;
      border-radius: $border-radius;
      @media screen and (max-width: 991px) {
        border-radius: initial;
      }
    }
  }

  &__right {
    padding: $space-2;
    display: flex;
    align-items: center;
    @media screen and (max-width: 991px) {
      padding: $space-1 $font-size $space-2;
    }
  }

  &__content {
  }

  &__pretitle {
    color: $color-white;
    text-transform: uppercase;
    font-size: $font-size-small;
    margin-bottom: $font-size-smaller;
    font-weight: $font-weight-bold;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: $header-2;
    margin-bottom: $space-1;
    line-height: 1.2;
    color: $color-white;
    text-wrap: balance;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
    @media screen and (min-width: 1401px) {
      font-size: $header-1;
    }
  }

  &__description {
    color: $color-white;
    text-wrap: balance;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
    @media screen and (min-width: 1401px) {
      max-width: 70%;
    }
  }
  &__actions {
    margin-top: $space-1;
    display: flex;
    gap: $font-size;
  }
}

.destacados {
  display: flex;
  gap: $space-2;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: $font-size;
  }
  &__item {
    text-align: center;
    @media screen and (max-width: 767px) {
      display: flex;
      gap: $space-1;
    }
  }

  &__icono {
    margin-bottom: $space-1;
    img,
    svg {
      height: 64px;
      width: auto;
      @media screen and (max-width: 767px) {
        width: 40px;
      }
    }
  }

  &__content {
    @media screen and (max-width: 767px) {
      text-align: left;
    }
  }

  &__titulo {
    font-size: $header-4;
    font-weight: $font-weight-bold;
    color: $color-primary-dark;
    margin-bottom: $font-size;
    @media screen and (max-width: 767px) {
      margin-bottom: calc($font-size-smaller / 2);
    }
  }

  &__descripcion {
    font-size: $font-size-small;
  }
  &__actions {
    display: flex;
    gap: $font-size;
  }
}

.cfw {
  background-color: $color-primary-dark;
  color: $color-white;
  position: relative;
  &__imagen {
    position: absolute;
    inset: 0;
    z-index: 1;
    &::before {
      @include overlay-deg;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 700px;
    padding-bottom: $space-4;
    position: relative;
    z-index: 1;
    gap: $font-size;
    @media screen and (min-width: 1401px) {
      height: 800px;
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }
    @media screen and (max-width: 767px) {
      height: 600px;
    }
  }

  &__left {
    flex: 0 0 45%;
    @media screen and (max-width: 767px) {
      flex: initial;
    }
  }
  &__titulo {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }
  &__descripcion {
    font-size: $font-size-small;
    margin-top: $font-size;
  }
  &__actions {
  }
}

.hits {
  padding: $space-4 0;
  background: $color-background-alt;
  &__slider {
    padding-bottom: $font-size;
  }

  &__container {
  }

  &__distribucion {
    margin-top: $space-5;
  }
}

.hit {
  padding: 0 $font-size-smaller $space-1 $font-size-smaller;
  &__wrapper {
    background-color: $color-white;
    box-shadow: $box-shadow;
    border-radius: $font-size;
    padding: $space-1;
    display: flex;
    gap: $font-size;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $font-size-smaller;
    }
  }
  &__ico {
    flex: 0 0 50px;
    @media screen and (max-width: 767px) {
      flex: 1 1 30px;
    }

    img {
      margin-top: $font-size-smaller;
      width: 100%;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 50px;
      }
    }
  }

  &__content {
  }

  &__valor {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    color: $color-primary-dark;
  }

  &__title {
    color: $color-primary;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size-smaller;
    font-size: $header-4;
  }

  &__description {
    font-size: $font-size-small;
  }
}
.container {
}

.world {
  display: flex;
  gap: $space-4;
  align-items: center;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: $space-2;
  }
  &__left {
    flex: 1 1 40%;
    @media screen and (max-width: 991px) {
      flex: 1;
    }
  }

  &__right {
    img {
      width: 100%;
      height: auto;
    }
    @media screen and (max-width: 991px) {
      flex: 1;
    }
  }
}

.cita {
  padding: $space-4 0;
  &__container {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: $space-4;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-1;
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__imagen {
    img {
      // width: 100%;
      // height: auto;
      width: 50vw;
      position: relative;
      margin-right: -50vw;
      left: 0;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.cita-quote {
  &__icono {
    width: 50px;
    height: auto;
    margin-bottom: $space-1;
  }

  &__titulo {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
      margin-bottom: $font-size;
    }
  }

  &__meta {
    font-size: $font-size-small;
  }
}

.snov {
  position: relative;
  height: auto;
  padding-bottom: $space-1;
  &__item {
    position: relative;
    border-radius: $font-size;
    overflow: hidden;
    margin-left: $font-size-smaller;
    margin-right: $font-size-smaller;
    aspect-ratio: 16 / 10;
    @media screen and (min-width: 1401px) {
      height: 800px;
    }
    @media screen and (min-width: 1601px) {
      height: 900px;
    }
  }
  &__picture {
    position: absolute;
    inset: 0;
    img {
    }

    // position: absolute;
    inset: 0;
    &::before {
      z-index: 1;
      @include overlay-deg;
    }
  }
  &__image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    inset: 0;
  }
  &__container {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    color: $color-white;
    padding-bottom: $space-2;
    padding-left: $space-2;
    padding-right: $space-2;
    @media screen and (max-width: 767px) {
      padding-bottom: $space-1;
      padding-left: $space-1;
      padding-right: $space-1;
    }
  }
  &__tax {
    margin-bottom: calc($font-size / 2);
  }
  &__title {
    font-size: $header-2;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }
  &__actions {
    .boton {
      @media screen and (max-width: 575px) {
        padding: 0.4rem 0.8em;
      }
    }
  }
}
