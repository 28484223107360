.wpcf7 {
  margin: 0rem 0 2rem;
  padding: $space-2;
  background: $color-background-alt;
  @media screen and (max-width: 767px) {
    padding: $font-size;
  }
  .col,
  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: 500;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    // text-transform: uppercase;
    margin-bottom: 0.2rem !important;
  }

  .form-control {
    width: 100%;
    padding: 0.575rem 0.75rem;
    font-size: 0.85rem;
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
  }
}

.gdpr-text {
  margin-top: $font-size;
  font-size: 0.6rem;
  color: $color-grey-3;
  color: rgba(0, 0, 0, 0.5);
}

.wpcf7-acceptance {
  .wpcf7-list-item {
    margin-left: 0;
    font-size: $font-size-small;
    a {
      color: $color-grey-1;
      text-decoration: underline;
      font-weight: $font-weight-medium;
    }
  }
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

input[type="file"] {
  // color: transparent;
  font-size: 12px;
}
// input[type="file"]::-webkit-file-upload-button {
//   visibility: hidden;
// }
// input[type="file"]::before {
//   content: "Select some files";
//   color: black;
//   display: inline-block;
//   background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
//   border: 1px solid #999;
//   border-radius: 3px;
//   padding: 5px 8px;
//   outline: none;
//   white-space: nowrap;
//   -webkit-user-select: none;
//   cursor: pointer;
//   text-shadow: 1px 1px #fff;
//   font-weight: 700;
//   font-size: 10pt;
// }
// input[type="file"]:hover::before {
//   border-color: black;
// }
// input[type="file"]:active {
//   outline: 0;
// }
// input[type="file"]:active::before {
//   background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
// }

// Mostrar el badge recapcha solo en la pagina de contacto
body:not(.page-template-page-contacto) {
  .grecaptcha-badge {
    visibility: hidden;
  }
}
