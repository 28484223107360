.historia {
  padding: $space-4 0;

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-4;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__picture {
    @media screen and (min-width: 992px) {
      width: 50vw;
      position: relative;
      margin-left: -50vw;
      left: 100%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.fores-info {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: $space-5;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  &__col {
  }

  &__title {
    font-size: $header-1;
    font-weight: $font-weight-bold;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
      margin-bottom: $space-1;
    }
  }

  &__content {
  }

  &__imagen {
    margin-top: $space-3;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.filosofia {
  &__item {
    margin-bottom: $space-4;
    width: 80%;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }

  &__imagen {
    margin-bottom: $space-2;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    margin-left: $space-4;
    @media screen and (max-width: 991px) {
      margin-left: $space-1;
    }
  }

  &__titulo {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size;
  }

  &__descripcion {
  }
}

.consejo {
  padding: $space-4 0;
  &__grid {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    gap: $space-4;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__imagen {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
  }

  &__title {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
  }
}

.posicion {
  margin-bottom: $space-2;
  &__title {
    font-size: $header-4;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size;
    color: $color-primary;
  }

  &__items {
  }

  &__item {
    margin-bottom: $font-size-smaller;
  }

  &__nombre {
    font-weight: $font-weight-bold;
  }

  &__cargo {
    color: $color-grey-3;
    font-size: $font-size-small;
  }
}
