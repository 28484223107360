.story {
  color: $color-white;
  &__item {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: $space-3;
    padding-bottom: $space-3;
    margin-bottom: $space-2;
    overflow: hidden;
    @media screen and (max-width: 991px) {
      grid-template-columns: auto 1fr;
      gap: $space-1;
    }
    &:last-child {
      .story__year__circle {
        &::after {
          height: 50px;
        }
      }
    }
  }

  &__image {
    min-height: 250px;
    @media screen and (max-width: 991px) {
      display: none;
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
      aspect-ratio: 16 / 10;
      object-fit: cover;
    }
  }

  &__image-resp {
    margin-bottom: $space-1;
    @media screen and (min-width: 992px) {
      display: none;
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
      aspect-ratio: 16 / 10;
      object-fit: cover;
    }
  }

  &__year {
    $year: 5.625rem;

    &__circle {
      display: inline-flex;
      background-color: $color-white;
      color: $color-primary-dark;
      border-radius: 50px;
      width: $year;
      height: $year;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      position: relative;
      @media screen and (max-width: 767px) {
        font-size: $font-size-small;
        width: $year - 2.5rem;
        height: $year - 2.5rem;
      }
      &::after {
        content: "";
        position: absolute;
        height: 1000px;
        width: 1px;
        background: $color-white;
        // background: red;
        bottom: 0;
        top: $year + $space-2;
        left: 45px;
        @media screen and (max-width: 767px) {
          top: $year;
          left: 25px;
        }
      }
    }
  }

  &__content {
  }

  &__title {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    @media screen and (max-width: 991px) {
      font-size: $header-3;
    }
  }

  &__date {
  }

  &__description {
  }
}
