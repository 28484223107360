@import "../../dist/vendor/bootstrap/css/bootstrap.min.css";

@import "base/variables";
@import "base/body";
@import "base/utilities";
@import "base/typography";
@import "base/mixins";

@import "layout/header";
@import "layout/footer";
@import "layout/nav";

@import "components/storybook"; // borrar para producción
@import "components/page-title";
@import "components/buttons";
@import "components/articles";
@import "components/forms";
@import "components/cta";
@import "components/slick";

@import "pages/inicio"; //okey
@import "pages/contacto";
@import "pages/productos";
@import "pages/plantas";
@import "pages/valores";

@import "pages/fores";
@import "pages/historia";
@import "pages/blog";
