.menu-header {
  @include reset-ul;
  display: flex;

  .menu-item {
    a {
      font-weight: $font-weight-medium;
      padding: 0.7rem 1.2rem;
      text-decoration: none;
      color: $color-dark;
      @media screen and (max-width: 1349px) {
        padding: 0.7rem 0.9rem;
      }
    }
    &.current-menu-item {
      a {
        color: $color-primary;
      }
    }
  }
  li.menu-item-has-children {
    position: relative;
    padding-right: 0.5rem;
    .sub-menu {
      background-color: $color-dark;

      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: none;
      position: absolute;
      box-shadow: $box-shadow;
      min-width: 220px;
      width: max-content;
      top: 2.5rem;
      z-index: 2;
      border-radius: 5px;
      li {
        a {
          color: $color-white;
          font-size: 0.9rem;
          padding: 0.5rem 1.25rem;
          font-weight: $font-weight-medium;
          display: block;
          text-decoration: none;
          font-size: $font-size;
          &:hover {
            background-color: $color-secondary;
            color: $color-dark;
          }
        }
      }
    }
  }
}

.menu-header {
  .menu-item-has-children {
    margin-right: $font-size;
    @media screen and (max-width: 1320px) {
      margin-right: 0.4rem;
    }
    @media screen and (max-width: 1154px) {
      margin-right: 0.5rem;
    }
    & > a {
      &::before {
        content: "";
        position: absolute;
        display: block;
        background: url("../assets/ico/ico-chevron-down.svg") no-repeat center
          center;
        // filter: brightness(0) invert(1);
        background-size: auto;
        width: 10px;
        height: 7px;
        background-size: cover;
        opacity: 0.6;
        right: 5px;
        top: 10px;
      }
    }
  }
}

.menu-header {
  &.menu-header--right {
    .menu-item-actualidad {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .menu-item-contacto {
      margin-left: $space-1;
      @media screen and (max-width: 1349px) {
        margin-left: $font-size;
      }
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
      @media screen and (max-width: 369px) {
        display: none;
      }
      a {
        background-color: $color-primary;
        border-radius: 50px;
        color: $color-white !important;
        font-size: $font-size-small;
        white-space: nowrap;
        @media screen and (max-width: 767px) {
          padding: 6px 10px;
        }
        @media screen and (max-width: 420px) {
          font-size: $font-size-smaller;
        }
      }
    }
  }
}

.fores-subnav {
  background: $color-grey-1;
  color: $color-white;
  padding: $font-size 0;
  font-size: $font-size-small;
  .container {
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
  &__content {
    display: flex;
    gap: $space-2;
    @media screen and (max-width: 767px) {
      gap: $font-size;
    }
  }

  &__title {
    white-space: nowrap;
    color: $color-secondary;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 575px) {
      font-size: $font-size-smaller;
      display: none;
    }
  }
}

.subnav-menu {
  width: 100%;
  &__nav {
    @include reset-ul;
    display: flex;
    flex-wrap: nowrap;
    gap: $space-1;
    font-size: $font-size-small;
    overflow-x: auto;
    width: 100%;
    @media screen and (max-width: 767px) {
      gap: $font-size-smaller;
    }
    .menu-item {
      &:first-child {
        margin-left: $font-size-small;
      }
      &:last-child {
        margin-right: $font-size-small;
      }
      a {
        color: $color-white;
        text-decoration: none;
        padding-top: 3px;
        padding-bottom: 3px;
        border-bottom: 2px solid transparent;
        white-space: nowrap;
        display: inline-flex;
        @media screen and (max-width: 767px) {
          padding: 5px 10px;
          background-color: $color-white;
          color: $color-grey-2;
          border-radius: 50px;
          font-size: $font-size-smaller;
          font-weight: $font-weight-medium;
          border-bottom: 0;
        }
        &:hover {
          border-bottom: 2px solid $color-white;
        }
      }
      &.current-menu-item {
        a {
          border-bottom: 2px solid $color-white;
          @media screen and (max-width: 767px) {
            background-color: $color-secondary;
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.nav-terms {
  display: flex;
  gap: $font-size;
  margin-top: $space-1;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    gap: $font-size-smaller;
  }
  &__item {
    a {
      color: $color-white;
      text-decoration: none;
      &:hover {
        color: $color-secondary;
      }
      @media screen and (max-width: 767px) {
        padding: 5px 10px;
        background-color: $color-white;
        color: $color-grey-2;
        border-radius: 50px;
        font-size: $font-size-smaller;
        font-weight: $font-weight-medium;
      }
    }
    &--active {
      a {
        color: $color-secondary;
        @media screen and (max-width: 767px) {
          background-color: $color-primary !important;
          color: $color-white !important;
        }
      }
    }
    &--all {
      a {
        font-weight: $font-weight-bold;
        // @media screen and (max-width: 767px) {
        //   background-color: $color-secondary !important;
        //   color: $color-grey-1 !important;
        // }
      }
    }
  }
}

.post-type-archive-productos {
  .nav-terms {
    &__item {
      &--all {
        a {
          color: $color-secondary;
          @media screen and (max-width: 767px) {
            background-color: $color-primary !important;
            color: $color-white !important;
          }
        }
      }
    }
  }
}

.nav-legal {
  &__nav {
    @include reset-ul;
    display: flex;
    gap: $space-1;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      gap: $font-size-smaller;
    }
  }
}

.nav-producto {
  .menu-item {
    a {
      color: $color-primary;
    }
    &.bold {
      a {
        font-weight: $font-weight-bold;
      }
    }
    &.color-arkit {
      a {
        color: $color-arkit;
      }
    }
  }
}

.offcanvas {
  &-header,
  &-body {
    padding-left: $space-2;
    padding-right: $space-2;
  }
  &-body__lang {
    margin-top: $space-1;
  }

  // &-header {
  // }
  // &-body {
  // }
}

.offnav {
  &__nav {
    @include reset-ul;
    & > li.menu-item {
      margin-bottom: $font-size-smaller;
      a {
        font-size: $header-4;
        font-weight: $font-weight-bold;
        &:hover {
          color: $color-primary;
        }
      }
      &.current-menu-item {
        a {
          color: $color-primary;
        }
      }
      & > .sub-menu {
        margin-top: calc($font-size-smaller / 2);
        @include reset-ul;
        li.menu-item {
          a {
            font-size: $header-5;
            font-weight: $font-weight-medium;
            color: $color-grey-3;
            &:hover {
              color: $color-primary;
            }
          }
          &.current-menu-item {
            a {
              color: $color-primary;
            }
          }
        }
      }
    }
  }
}

.wpml-ls-sidebars-sidebar-idiomas.wpml-ls-legacy-dropdown {
  // background: rgb(34, 255, 0) !important;
  padding: 0 !important;
  border: 0 !important;
  width: max-content !important;
  max-width: 2.5rem;

  li {
    &.wpml-ls-slot-sidebar-idiomas {
      a {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $color-grey-5 !important;
        border-radius: 6px;
        padding: 0px 3px 2px !important;
        display: inline-block;
        .wpml-ls-flag {
          border-radius: 1px;
        }
      }
      a.wpml-ls-item-toggle {
        &::after {
          display: none;
        }
      }
    }
  }
  ul.wpml-ls-sub-menu {
    border: none;
  }
}
.wpml-ls-sidebars-sidebar-offcanvas-idiomas {
  // background: rgb(34, 255, 0) !important;
  padding: 0 !important;
  border: 0 !important;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    li.wpml-ls-item {
      a.wpml-ls-link {
        border: 2px solid $color-grey-4 !important;
        border-radius: 6px;
        padding: 0px 3px 2px !important;
        display: inline-block;
        .wpml-ls-flag {
          border-radius: 1px;
        }
      }
    }
  }
}
