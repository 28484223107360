.g-plantas {
  // padding: $space-4 0;
  &__container {
    &:nth-child(2n) {
      @media screen and (min-width: 992px) {
        background: $color-background-alt;
      }
    }
  }
  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-3;
    padding-top: $space-4;
    padding-bottom: $space-4;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-2;
      padding-top: $space-2;
      padding-bottom: $space-2;
    }
    @media screen and (max-width: 767px) {
      gap: $font-size;
    }
  }
  &__imagen {
    img {
      @include img-fluid;
    }
  }
  &__tipo {
    font-size: $header-4;
    color: $color-primary;
  }
  &__titulo {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    color: $color-dark;
    padding-bottom: $space-1;
    margin-bottom: $space-1;
    border-bottom: 1px dashed $color-grey-4;
    @media screen and (max-width: 767px) {
      border: 0;
      padding-bottom: 0;
      margin-bottom: $font-size-smaller;
    }
  }
  &__descripcion {
    margin-top: $space-1;
  }
  &__metros {
    margin-bottom: $space-1;
    display: flex;
    align-items: center;
    gap: $font-size;
    @media screen and (max-width: 767px) {
      margin-bottom: $font-size-smaller;
    }
    span {
      font-weight: $font-weight-bold;
      font-size: $header-4;
    }
    img,
    svg {
      width: 35px;
      height: auto;
    }
  }
  &__map {
    margin-top: $space-2;
    @media screen and (max-width: 767px) {
      margin-top: $font-size;
    }
    iframe {
      border-radius: $border-radius-min;

      width: 100%;
      height: 400px;
      // box-shadow: $box-shadow-small;
      // border: 1px dashed $color-grey-4 !important;
      // padding: calc($font-size-smaller / 2);
    }
  }
}
