.gblog {
  margin-bottom: $space-5;
  margin-top: -$space-5 * 2 + $space-1;
  &__items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $space-2;
    @media screen and (min-width: 992px) {
      grid-template-columns: repeat(6, 1fr);
      gap: $space-4 $space-2;
    }
  }

  &__item {
    @media screen and (min-width: 992px) {
      grid-column: span 2;
      &:nth-child(1),
      &:nth-child(2) {
        grid-column: span 3;
        .post-thumb__title {
          font-size: $header-2;
        }
      }
    }
  }
}

.gblog-related {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $space-2;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}

.post-thumb {
  &__image {
    background-color: $color-primary-dark;
    margin-bottom: $font-size;
    display: inline-flex;
    border-radius: calc($font-size / 2);
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
  }

  &__tax {
    font-size: $font-size-small;
    margin-bottom: $font-size-smaller;
    a {
      color: $color-primary;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
  }

  &__title {
    font-size: $header-3;
    font-weight: $font-weight-bold;
  }
}
