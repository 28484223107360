body.term-bano,
body.term-bagno,
body.term-banho,
body.term-bad,
body.term-bathroom,
body.term-salle-de-bain {
  .page-title,
  .bg-dark {
    background: $color-arkit !important;
  }
}

.g-novedades {
  display: grid;
  gap: $space-5;
}
.novedad {
  color: $color-white;
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  gap: $space-4;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: $space-1;
  }
  @media screen and (max-width: 767px) {
    gap: $font-size;
  }
  &__imagen {
    position: relative;
    display: flex;
    gap: $font-size;
    width: 100%;
    @media screen and (min-width: 992px) {
      // width: 80%;
    }
    a {
      display: block;
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__arkit {
    position: absolute;
    right: -$space-2;
    top: $space-5 - $font-size;
    display: inline-block;
    width: auto;
    background: $color-grey-2;
    height: auto;
    z-index: 1;
    padding: 0.5rem 1.2rem;
    img {
      width: 100px;
    }
    @media screen and (max-width: 991px) {
      top: initial;
      left: 0;
      bottom: 0;
      right: initial;
      padding: 0.2rem 0.5rem;
      font-size: $font-size-small;
    }
  }
  &__tax {
    position: absolute;
    background: $color-secondary;
    color: $color-grey-1;
    font-size: $header-4;
    padding: 0.5rem 1.2rem;
    font-weight: $font-weight-bold;
    right: -$space-2;
    top: $space-2;
    display: inline-flex;
    @media screen and (max-width: 991px) {
      left: 0;
      top: 0;
      right: initial;
      padding: 0.2rem 0.5rem;
      font-size: $font-size-small;
    }
  }
  &__content {
  }

  &__titulo {
    font-size: $header-1;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      font-size: $header-2;
      margin-bottom: $font-size;
    }
  }

  &__meta {
    font-size: $font-size-small;
  }

  &__colores {
    margin-bottom: calc($font-size-small / 2);
    ul {
      @include reset-ul;
      display: flex;
      gap: $font-size;
    }
  }

  &__disponible {
    text-transform: capitalize;
  }

  &__galeria {
    // margin-top: $space-2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: masonry;
    gap: 6px;
    margin-top: $space-2;
    img {
      width: 100%;
      height: auto;
    }
    @media screen and (max-width: 767px) {
      margin-top: $font-size;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  // &__galeria {
  //   display: flex;
  //   flex-flow: column wrap;
  //   &::before,
  //   &::after {
  //     content: "";
  //     flex-basis: 100%;
  //     width: 0;
  //     order: 2;
  //   }
  //   a {
  //     &:nth-child(3n + 1) {
  //       order: 1;
  //     }
  //     &:nth-child(3n + 2) {
  //       order: 2;
  //     }
  //     &:nth-child(3n) {
  //       order: 3;
  //     }
  //   }
  //   img {
  //     width: 100%;
  //     height: auto;
  //   }
  //   @media screen and (max-width: 767px) {
  //     margin-top: $font-size;
  //     grid-template-columns: repeat(4, 1fr);
  //   }
  // }
}

.navigation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $space-1;
}
