.footer {
  background: $color-background-alt;
  padding-top: $space-3;
  padding-bottom: $space-3;
  &__container {
  }

  &__top {
    display: grid;
    grid-template-columns: 9fr 3fr;
    gap: $space-2;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__cols {
    display: grid;
    gap: $space-1;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: $space-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__col {
  }

  &__logo {
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    gap: $font-size;
    @media screen and (max-width: 767px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    .logo {
      height: 50px;
      width: auto;
      max-width: 130px;
    }
  }

  &__footer {
    font-size: $font-size-small;
    display: flex;
    gap: $space-1;
  }

  &__left {
    display: flex;
    gap: $space-1;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: $font-size;
    }
  }

  &__copy {
  }

  &__right {
  }
}

.widget-footer {
  &__title {
    font-size: $font-size;
    text-transform: uppercase;
    margin-bottom: $space-1;
    font-weight: $font-weight-bold;
    color: $color-primary-dark;
    @media screen and (max-width: 767px) {
      margin-bottom: $font-size-smaller;
    }
  }
  ul.menu {
    @include reset-ul;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      gap: 5px 12px;
    }
    .menu-item {
      a {
        font-size: $font-size-small;
      }
    }
  }
}
