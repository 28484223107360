.cursor-pointer {
  cursor: pointer;
}

// Utilitats de colors i backgrounds
// ------------------------------------------------
$colors: (
  "primary" $color-primary,
  "primary-light" $color-primary-light,
  "primary-dark" $color-primary-dark,
  "secondary" $color-secondary,
  "secondary-light" $color-secondary-light,
  "secondary-dark" $color-secondary-dark,
  "accent" $color-accent,
  "accent-light" $color-accent-light,
  "accent-dark" $color-accent-dark,
  "color-arkit" $color-arkit,
  "white" $color-white,
  "dark" $color-dark,
  "grey-1" $color-grey-1,
  "grey-2" $color-grey-2,
  "grey-3" $color-grey-3,
  "grey-4" $color-grey-4,
  "grey-5" $color-grey-5,
  "grey-6" $color-grey-6,
  "background-alt" $color-background-alt
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background: $color !important;
  }
}

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color;
  }
}

// Mides funcionals per a espais
// ------------------------------------------------
$sizes: (
  "top" "t" "0" $space-0,
  "top" "t" "1" $space-1,
  "top" "t" "2" $space-2,
  "top" "t" "3" $space-3,
  "top" "t" "4" $space-4,
  "top" "t" "5" $space-5,
  "bottom" "b" "0" $space-0,
  "bottom" "b" "1" $space-1,
  "bottom" "b" "2" $space-2,
  "bottom" "b" "3" $space-3,
  "bottom" "b" "4" $space-4,
  "bottom" "b" "5" $space-5
);

// loop top i bottom
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$d}-#{$name} {
    padding-#{$direction}: $size;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        padding-#{$direction}: calc($size / 1.5) !important;
      } @else {
        padding-#{$direction}: $size !important;
      }
    }
  }
  .mar-#{$d}-#{$name} {
    margin-#{$direction}: $size;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        margin-#{$direction}: calc($size / 1.5) !important;
      } @else {
        margin-#{$direction}: $size !important;
      }
    }
  }
}

// loop top i bottom en y
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$name} {
    padding: $size 0;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        padding: calc($size / 1.5) 0 !important;
      } @else {
        padding: $size 0 !important;
      }
    }
  }
  .mar-#{$name} {
    margin: $size 0;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        margin: calc($size / 1.5) 0 !important;
      } @else {
        margin: $size 0 !important;
      }
    }
  }
}
