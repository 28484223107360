.slick-slider {
  .slick-list {
  }
  ul.slick-dots {
    li {
      button {
        &::before {
          font-size: 12px;
        }
      }
      &.slick-active {
        &::before {
          color: $color-primary;
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    z-index: 3;
  }
  .slick-next {
    right: $space-3 * 2;
  }
  .slick-prev {
    left: $space-3 * 2;
  }
}
