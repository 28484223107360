.g-promo {
  padding: $space-3 0;

  &__items {
    display: flex;
    flex-direction: column;
    gap: $space-3;
  }

  &__item {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: $space-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-1;
    }
  }

  &__picture {
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
      object-fit: cover;
    }
  }

  &__right {
  }

  &__pre {
    text-transform: uppercase;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size;
    @media screen and (max-width: 991px) {
      margin-bottom: calc($font-size / 2);
    }
  }

  &__title {
    font-size: $header-2;
    color: $color-primary;
    line-height: 1.2;
    margin-bottom: $space-1;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 991px) {
      margin-bottom: calc($font-size / 2);
      font-size: $header-3;
    }
  }

  &__description {
  }
}

.valores-wrapper {
  padding: $space-3 0;
  &__cols {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: $space-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

  &__left {
    img {
      // padding: $space-1;
      // border: 1px dashed $color-grey-4;
      border-radius: $border-radius;
      width: 100%;
      height: auto;
    }
  }

  &__article {
  }
}
