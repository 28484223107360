.header {
  padding: $space-1 0;
  position: sticky;
  top: 0;
  z-index: 5;
  background: $color-white;
  // background: red;
  transition: $trans;

  &--sticky {
    padding: $font-size-smaller 0;
    box-shadow: $box-shadow;
    transition: $trans;
  }
  @media screen and (max-width: 767px) {
    padding: $font-size-small 0;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    padding-left: $font-size;
    padding-right: $font-size;
    gap: $space-1;
    @media screen and (max-width: 767px) {
      gap: $font-size;
    }
    @media screen and (max-width: 400px) {
      gap: $font-size-smaller;
    }
  }

  &__left {
    display: flex;
    gap: $space-2;
    align-items: center;
    @media screen and (max-width: 1349px) {
      gap: $space-1;
    }
  }

  &__nav {
    @media screen and (max-width: 1149px) {
      display: none;
    }
  }

  &__right {
    margin-left: auto;
  }
}

.logo {
  width: 129px;
  height: auto;
  @media screen and (max-width: 767px) {
    width: 100px;
  }
}

a[data-bs-toggle="offcanvas"] {
  i {
    font-size: $header-3;
  }
}
