.page-title {
  background-color: $color-primary-dark;
  color: $color-white;
  padding: $space-1 0 $space-2;
  @media screen and (max-width: 767px) {
    padding: $font-size 0 $space-1;
  }
  &__h {
    font-size: $header-1;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
    &--link {
      display: flex;
      align-items: center;
      color: $color-secondary;
      flex-wrap: wrap;
      a {
        color: $color-white;
        font-weight: $font-weight-normal;
        transition: $trans;
        &:hover {
          text-decoration: underline;
          transition: $trans;
        }
      }
    }
  }
  &__subh {
    font-size: $header-4;
    margin-top: $font-size;
    @media screen and (min-width: 992px) {
      max-width: 60%;
      line-height: 1.6;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-5;
      margin-top: calc($font-size / 2);
      margin-bottom: 0;
    }
  }
  &__breadcrumbs {
    margin-bottom: $space-4 * 2;
    font-size: $font-size-smaller;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-2;
    }
    a {
      color: $color-white;
      font-weight: $font-weight-bold;
      text-decoration: none;
    }
  }
  &--single {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .page-title__h {
      font-size: $header-1;
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: 991px) {
        font-size: $header-2;
        width: 100%;
      }
      @media screen and (max-width: 575px) {
        font-size: $header-3;
        text-wrap: balance;
      }
    }
  }
}

.tax-tipo,
.post-type-archive-productos {
  .page-title {
    background-color: $color-grey-1;
    &__breadcrumbs {
      margin-bottom: $space-2;
    }
  }
}
