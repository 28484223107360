@mixin reset-ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

@mixin overlay-deg {
  content: "";
  position: absolute;
  display: block;
  inset: 0;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

@mixin hr-bottom {
  padding-bottom: $space-2;
  margin-bottom: $space-2;
  border-bottom: 1px dashed $color-grey-4;
}

@mixin img-fluid {
  border-radius: $border-radius-min;
  max-width: 100%;
  height: auto;
}
