.g-contacto {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-3;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  &__left {
  }

  &__right {
  }
}

.contactos {
  @include hr-bottom;
  &__titulo {
    font-size: $header-2;
    color: $color-primary-dark;
    font-weight: $font-weight-bold;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
      margin-bottom: $space-1;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-2;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $font-size;
    }
  }

  &__item {
  }

  &__nombre {
    font-size: $header-4;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size;
    @media screen and (max-width: 767px) {
      margin-bottom: calc($font-size / 3);
    }
  }

  &__email {
    a {
      color: $color-primary;
    }
  }

  &__telefono {
    color: $color-grey-3;
  }
}

.contactos-post {
  @include hr-bottom;
  &__titulo {
    font-size: $header-2;
    color: $color-primary-dark;
    font-weight: $font-weight-bold;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
      margin-bottom: $space-1;
    }
  }
  &__p {
    margin-bottom: $space-1;
  }
  &__items {
    display: flex;
    width: 100%;
    gap: $space-1;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  &__actions {
    .boton {
      white-space: nowrap;
    }
  }

  &__content {
    width: 100%;
  }

  &__item {
    display: flex;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
      margin-bottom: 5px;
    }
    span {
      flex: 0 0 50%;
      font-weight: $font-weight-bold;
    }
    a {
      color: $color-primary;
    }
    &--whatsapp {
      span {
        color: $color-whatsapp-dark;
      }
    }
  }
}

.recursos {
  &__titulo {
    font-size: $header-2;
    color: $color-primary-dark;
    font-weight: $font-weight-bold;
    margin-bottom: $space-2;
  }

  &__files {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 16px;
      height: auto;
    }
    a {
      color: $color-primary;
    }
  }
}

.gmaps {
  iframe {
    width: 100%;
    height: 600px;
  }
}

.trabaja-desc {
  &__title {
    font-size: $header-2;
    color: $color-primary-dark;
    font-weight: $font-weight-bold;
    margin-bottom: $space-2;
  }

  &__desc {
    margin-bottom: $space-2;
  }

  &__imagen {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.video {
  background-color: $color-grey-1;
  padding: $space-4 0;
  &__container {
    iframe {
      box-shadow: $box-shadow;
      border-radius: 5px;
    }
  }
}
