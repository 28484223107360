.cta {
  padding: $space-4 0;
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__titulo {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
  }

  &__actions {
    display: flex;
    gap: $space-1;
    flex-wrap: wrap;
    justify-content: center;
    @media screen and (max-width: 767px) {
      gap: $font-size-smaller;
    }
  }
}

.cta-groc {
  background-color: $color-secondary;
  padding: $space-5 0;
  &__container {
    text-align: center;
    @media screen and (min-width: 992px) {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__pre {
    font-size: $header-5;
    font-weight: $font-weight-bold;
    // text-transform: uppercase;
  }

  &__titulo {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    margin-bottom: $space-2;
  }

  &__actions {
  }
}
